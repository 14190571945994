























































































import { Component, Vue } from 'vue-property-decorator';
import VueI18n, { LocaleMessages } from 'vue-i18n';

/** Shows the current filter state. */
@Component({
  props: {
  },
})
export default class LangEditComponent extends Vue {
  isOpen : boolean = false;
  langs : Array<string> = [];
  langKey : string = 'en';
  messages : LocaleMessages = {};

  data() {
    const { messages } = this.$i18n;
    return {
      isOpen: false,
      langs: this.$i18n.availableLocales,
      langKey: this.$i18n.locale,
      messages,
    };
  }

  onOpen() {
    this.langKey = this.$i18n.locale;
  }

  onClose() {
    this.messages = this.$i18n.messages;
  }

  onConfirm() {
    // Update language in-memory language data
    this.langs.forEach((langKey : string) => {
      this.$i18n.setLocaleMessage(langKey, this.messages[langKey]);
    });
    this.isOpen = false;
  }

  onDownload() {
    const content = JSON.stringify(this.messages[this.langKey], null, 2);
    this.download(content, `${this.langKey}.json`, 'application/json');
  }

  /* eslint class-methods-use-this: ["off"] */
  download(content : string, fileName : string, contentType : string) {
    const a = document.createElement('a');
    const file = new Blob([content], { type: contentType });
    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();
  }

  onUpload(param : any) {
    if (param === null) {
      // Do nothing - no file was selected
    } else if (Array.isArray(param)) {
      const files : Array<File> = param;
      files.forEach((file : File) => {
        this.upload(file);
      });
    } else {
      const file : File = param;
      this.upload(file);
    }
  }

  upload(file : File) {
    const langKey = file.name.split('.')[0];
    if (this.langs.indexOf(langKey) === -1) {
      this.$buefy.snackbar.open({
        // Note: message is used in v-html, so keep it safe from user input!
        message: 'File name is not a language key. Do not rename downloaded files.',
        type: 'is-assertive',
        queue: false,
      });
    } else {
      const reader = new FileReader();
      const self = this;
      reader.onload = (e : any) => {
        const contents = e.target.result;
        try {
          const messages = JSON.parse(contents);
          self.messages[langKey] = messages;
          self.$buefy.snackbar.open({
            // Note: message is used in v-html, so keep it safe from user input!
            message: `Loaded new messages for ${langKey} from file`,
            type: 'is-balanced',
            queue: false,
          });
        } catch (error) {
          self.$buefy.snackbar.open({
            // Note: message is used in v-html, so keep it safe from user input!
            message: `Could not parse ${langKey}.json as json.`,
            type: 'is-assertive',
            queue: false,
          });
        }
      };
      reader.readAsText(file);
    }
  }
}
