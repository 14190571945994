



































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import InfoBoxComponent from '@/components/InfoBoxComponent.vue';
import LocaleChangerComponent from '@/components/LocaleChangerComponent.vue';
import LangEditComponent from '@/components/LangEditComponent.vue';
import { Flavor } from '@/flavor/types';
import { flavor, flavorData } from '@/flavor';

@Component({
  components: {
    InfoBoxComponent,
    LocaleChangerComponent,
    LangEditComponent,
  },
})
export default class Login extends Vue {
  public username: string = '';
  public password: string = '';
  public isLoggingIn: boolean = false;
  flavor: Flavor = flavor;
  appName: string = flavorData.name;

  async onLogin() {
    try {
      this.isLoggingIn = true;
      await this.$store.dispatch('user/login', {
        username: this.username,
        password: this.password,
      });
      await this.$store.dispatch('survey/load');
      this.$router.push('/survey');
    } catch (e) {
      // Do nothing
    } finally {
      this.isLoggingIn = false;
    }
  }
}
