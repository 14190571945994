

















import { Component, Vue } from 'vue-property-decorator';
import i18n from 'vue-i18n';

@Component({
  props: {
    menuSection: String, // First part of the title (before |)
    viewTitle: String, // Second part of the title (after |)
  },
})
export default class LocaleChangerComponent extends Vue {
  data() {
    return {
      langs: this.$i18n.availableLocales,
      langNames: {
        en: 'English',
        de: 'German',
        fr: 'French',
        it: 'Italian',
        sv: 'Swedish',
        no: 'Norwegian',
        da: 'Danish',
      },
    };
  }
}
